﻿
import jQuery from 'jquery';
import 'bootstrap';

import 'datatables.net';
import 'datatables.net-bs4';


import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'jquery-ui';

global.jQuery = jQuery;
global.$ = jQuery;

require('./bootstrap-combobox.js');

$(function () {
    $('[data-toggle="tooltip"]').tooltip();

    $('.dataTables').DataTable({
        pageLength: 25,
        "searching": false,
        "lengthChange": false,
        "columnDefs": [
            { "orderable": false, "targets": 3 },
            { "orderable": false, "targets": 4 }
        ]
    });
});

